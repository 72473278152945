<template>
    <div class="container-page record-page">
        <div class="search-box">
        <el-form inline ref="searchForm" :model="searchForm">
                <el-form-item label="操作人员" style="width: 15rem;">
            <el-input
                size="small"
                placeholder="全部"
                style="width: 9rem;"
                v-model="searchForm.name"
                clearable
            ></el-input>
                </el-form-item>

                <el-form-item>
                    <el-form-item label="登录时间">
                <el-date-picker
                  size="small"
                  type="datetimerange"
                  v-model="importLog"
                  range-separator="~"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                >
                </el-date-picker>
              </el-form-item>
                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-search"
                    @click="operatorList"
                    >查询</el-button
                >
                </el-form-item>
            </el-form>
        </div>
        <el-table
      ref="tableData"
      :data="tableData"
      v-loading="$store.state.requestLoading"
      style="border-left: .5px solid #EBEEF5;border-right: .5px solid #EBEEF5;"
    >
      <el-table-column prop="operatorName" label="操作人员" width="100"> </el-table-column>
      <el-table-column label="操作时间" width="160">
        <template slot-scope="scope">
          {{
            scope.row.createTime
              ? $util.dateFormat(scope.row.createTime)
              : $util.dateFormat(scope.row.createTime)
          }}
        </template>
      </el-table-column>

      <el-table-column  label="导入进度">
          <template slot-scope="scope">
              <span v-if="scope.row.importStatus===1">正在导入</span>
              <span v-if="scope.row.importStatus===2" style="color:#67C23A;">成功</span>
              <span v-if="scope.row.importStatus===3" style="color:#F56C6C;">导入失败</span>
          </template>
      </el-table-column>
      <el-table-column label="导入结果">
          <template slot-scope="scope">
              <span v-if="!scope.row.errorDetail">——</span>
              <el-link
               style="width:20rem;display:inline-block;overflow:hidden;white-space: nowrap;text-overflow:ellipsis;"
               @click.native.prevent="recordDetail(scope.row)">
                  {{scope.row.errorDetail}}
                  </el-link>
          </template>
      </el-table-column>
    </el-table>
    <div class="pager">
      <el-pagination background layout="total, prev, pager, next"
      :page-size="pager.pageSize"
        :total="pager.total"
        @current-change="currentChange">
      </el-pagination>
    </div>
    <recordErroDetal
      v-if="dialogName === 'recordErroDetal'"
      @close="dialogName = ''"
      :params='datas'
    ></recordErroDetal>
    </div>
</template>

<script>
import recordErroDetal from './recordErroDetal.vue'
export default {
  components: {
    recordErroDetal
  },
  data () {
    return {
      importLog: [],
      searchForm: {
        name: '',
        startDate: '',
        endDate: ''
      },
      tableData: [

      ],
      pager: {
        total: 0,
        pageNo: 1,
        pageSize: 10
      },
      dialogName: '',
      datas: {}
    }
  },
  created () {
    this.operatorList(Object.assign(this.searchForm, { pageNo: 1, pageSize: 10 }))
  },
  mounted () {

  },
  methods: {
    // 账号列表查询
    async operatorList () {
      const res = await this.$api.importLog(this.searchForm)

      if (res !== 'fail&&error') {
        this.tableData = res.data
        this.tableData.forEach(item => {
          item.errorDetail = item.errorDetail
            ? item.errorDetail.replace('[', '').replace(']', '') : ''
        })
        this.pager.total = res.total
      }
    },
    // 分页查询
    currentChange (pageNo) {
      this.pager.pageNo = pageNo
      this.operatorList(Object.assign(this.searchForm, { pageNo: this.pager.pageNo }))
    },
    recordDetail (obj) {
      this.dialogName = 'recordErroDetal'
      this.datas = obj
    }
  },
  watch: {
    importLog (arrVal) {
      if (arrVal) {
        this.searchForm.startDate = new Date(arrVal[0]).getTime()
        this.searchForm.endDate = new Date(arrVal[1]).getTime()
      } else {
        this.searchForm.startDate = this.searchForm.endDate = null
      }
    }

  }
}
</script>
<style lang="scss"  scoped>
.record-page {
  .search-box {
    border: .5px solid #ebeef5;
    padding: 0.66rem 1.2rem 0.88rem;
    .el-form-item {
      margin-bottom: 0.66rem;
    }
  }
  .pager {
    text-align: right;
    padding: 0.66rem 0 0;
  }

  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    height: 12px;
    cursor: pointer;
    background-color: #6b6b6b5e;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgba(88, 87, 87, 0.219);
  }
}
</style>
