<template>
  <el-dialog
    width="40rem"
    :visible="true"
    top="8vh"
    @close="$emit('close')"
    custom-class="them-dialog"
  >
    <div slot="title">
      导入结果
    </div>
    <div>
        <el-form  size="mini" label-width="6rem">
          <el-form-item label="操作人："  class="p-item">{{params.operatorName}}</el-form-item>
          <el-form-item label="操作时间："  class="p-item"> {{params.createTime}}</el-form-item>
          <el-form-item label="导入结果：" class="p-item">
              <div style="overflow:auto;height:15rem;">
                  {{params.errorDetail}}
              </div>

          </el-form-item>
        </el-form>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    params: {
      default: null,
      type: Object
    }
  },
  data () {
    return {
      title: '导入结果'
    }
  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    cursor: pointer;
    background-color: #6b6b6b5e;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgba(88, 87, 87, 0.219);
  }
  </style>
